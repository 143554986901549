
import { Options, Vue } from 'vue-class-component'
import TablePage from '../components/Table.vue'

@Options({
  components: {
    TablePage
  }
})
export default class ScriptCustomPage extends Vue {
  title = 'Customization'
}
